import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class PageService {
  getPages(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/pages?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/pages?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  async getJobTags() {
    return axios.get(API_URL + `job_tags`, {
      headers: authHeader(),
    });
  }

  get(id) {
    return axios.get(API_URL + `admin/pages/${id}`, { headers: authHeader() });
  }

  create(c) {
    return axios.post(API_URL + `admin/pages/`, { ...c }, { headers: authHeader() });
  }

  update(data, tags) {
    return axios.put(API_URL + `admin/pages/${data.id}`, { ...data, tags: tags }, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/pages/${id}`, { headers: authHeader() });
  }

  // page items

  getPageItems(page_id) {
    return axios.get(API_URL + `admin/page_items/page/${page_id}`, { headers: authHeader() });
  }
  
  getPageItem(id) {
    return axios.get(API_URL + `admin/page_items/${id}`, { headers: authHeader() });
  }

  createPageItem(c) {
    return axios.post(API_URL + `admin/page_items/`, { ...c }, { headers: authHeader() });
  }

  updatePageItem(data, tags) {
    return axios.put(API_URL + `admin/page_items/${data.id}`, { ...data, tags: tags }, { headers: authHeader() });
  }

  deletePageItem(id) {
    return axios.delete(API_URL + `admin/page_items/${id}`, { headers: authHeader() });
  }

  // parents and children

  getParentPages(currentPage) {
    return axios.get(API_URL + `admin/pages/root/${currentPage}`, { headers: authHeader() });
  }

  getChildren(currentPage) {
    return axios.get(API_URL + `admin/pages/children/${currentPage}`, { headers: authHeader() });
  }

}

export default new PageService();
