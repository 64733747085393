<template>
  <div>
    <OverflowMenuVertical32 class="w-5 h-5" aria-hidden="true" @click="open = true" />

    <TransitionRoot as="template" :show=open>
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Blok
                  </DialogTitle>
                </div>

                <form @submit.prevent="handleSave" method="POST">
                  
                  <div class="py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                      
                      <div class="col-span-6 sm:col-span-6">
                        <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
                        <input type="text" name="title" id="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="pageBlock.title">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="subtitle" class="block text-sm font-medium text-gray-700">Subtitle</label>
                        <input type="text" name="subtitle" id="subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="pageBlock.subtitle">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="type" class="block text-sm font-medium text-gray-700">Type blok</label>
                        <select name="type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" @change="onChange($event)" v-model="pageBlock.type">
                          <option value="news" :selected="pageBlock.type === 'news'">Nieuws</option>
                          <option value="event" :selected="pageBlock.type === 'event'">Event</option>
                          <option value="download" :selected="pageBlock.type === 'download'">Download</option>
                          <option value="whitepaper" :selected="pageBlock.type === 'whitepaper'">White paper</option>
                          <option value="blog" :selected="pageBlock.type === 'blog'">Blog</option>
                          <option value="solution_brief" :selected="pageBlock.type === 'solution_brief'">Video</option>
                          <option value="referentiecase" :selected="pageBlock.type === 'referentiecase'">Referentiecase</option>
                        </select>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="custom_icon" class="block text-sm font-medium text-gray-700">Ander icon</label>
                        <input type="text" name="custom_icon" id="custom_icon" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="bijv. fa-save" v-model="pageBlock.custom_icon">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="link_title" class="block text-sm font-medium text-gray-700">Link titel (1 woord)</label>
                        <input type="text" name="link_title" id="link_title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="" v-model="pageBlock.link_title">
                      </div>

                      <div class="col-span-6 sm:col-span-6">
                        <label for="link" class="block text-sm font-medium text-gray-700">Link url</label>
                        <input type="text" name="link" id="link" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" placeholder="Hele url incl. https://" v-model="pageBlock.link">
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="highlight" class="block text-sm font-medium text-gray-700">
                          Highlight?
                        </label>
                        <div class="mt-1 flex">
                          <button type="button" :class="pageBlock.highlight ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="pageBlock.highlight = !pageBlock.highlight">
                            <span class="sr-only">Is aankondiging</span>
                            <span aria-hidden="true" :class="pageBlock.highlight ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                          </button>
                        </div>
                      </div>

                      <div class="col-span-6 sm:col-span-3">
                        <label for="highlight" class="block text-sm font-medium text-gray-700">
                          Opnen in nieuw venster?
                        </label>
                        <div class="mt-1 flex">
                          <button type="button" :class="pageBlock.new_window ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="pageBlock.new_window = !pageBlock.new_window">
                            <span class="sr-only">Is aankondiging</span>
                            <span aria-hidden="true" :class="pageBlock.new_window ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="py-3 text-right sm:px-6">
                    <button @click="destroy" v-if="pageBlock.id" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                      delete
                    </button>
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                      <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
                      {{saving ? 'saving': 'save'}}
                    </button>
                  </div>
                  
                </form>
                
              </div>
              <div class="mt-5 sm:mt-6">
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm" @click="open = false">
                  Go back to dashboard
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import PageService from '../../../services/admin/pages/service';
import { OverflowMenuVertical32 } from '@carbon/icons-vue';

export default {
  name: 'NewsitemFormComponent',
  props: ['page_block'],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    OverflowMenuVertical32
  },
  setup(props) {
    const open = ref(false)
    const loading = ref(false);
    const saving = ref(false);
    let pageBlock = ref(true);
    
    let { page_block } = toRefs(props);
    pageBlock = page_block.value;

    return {
      loading,
      saving,
      pageBlock,
      open
    }
  },
  methods: {
    async handleSave() {
      this.saving = true;
      if (this.pageBlock.id) {
        await PageService.updatePageItem(this.pageBlock);
      } else {
        await PageService.createPageItem(this.pageBlock);
        this.$emit('created');
      }
      this.saving = false;
    },
    async destroy() {
      if(confirm('are you sure?')) {
        await PageService.deletePageItem(this.pageBlock.id);
        this.open = false;
        this.$emit('deleted');
      }
    }
  }
}
</script>
