<template>
  <div>
    <h2 class="text-gray-500 text-xs font-medium uppercase tracking-wide">Bloks</h2>
    <ul role="list" class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
      <li v-for="item in pageItems" :key="item.id" class="col-span-4 flex shadow-sm rounded-md">
        <div :class="[item.highlight ? 'bg-orange-600':'bg-green-600', 'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md']">
          
        </div>
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <span class="text-gray-900 font-medium">{{item.title}}</span>
            <p class="text-gray-500">{{item.highlight ? 'highlight':''}}</p>
          </div>
          <div class="flex-shrink-0 pr-2">
            <button type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              <span class="sr-only">Open options</span>
              <PageBlockForm :page_block="item" @deleted="refreshPageItems" />
            </button>
          </div>
        </div>
        
      </li>

      <li class="col-span-4 flex shadow-sm rounded-md">
        <div class="bg-gray-600 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md">
          
        </div>
        <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <span class="text-gray-900 font-medium">Maak nieuw block aan</span>
            <p class="text-gray-500"></p>
          </div>
          <div class="flex-shrink-0 pr-2">
            <button type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              <span class="sr-only">Open options</span>
              <PageBlockForm :page_block="newPageItem" @created="refreshPageItems" />
            </button>
          </div>
        </div>
        
      </li>

    </ul>
  </div>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import PageService from '../../../services/admin/pages/service';
import PageBlockForm from '../../../components/admin/page_blocks/PageBlockForm';

export default {
  name: 'PageItems',
  props: {
    pageid: {
      type: String,
      default: null
    }
  },
  components: {
    PageBlockForm
  },
  setup(props) {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    // const route = useRoute()
    const { pageid } = toRefs(props);
    const pageItems = ref(null);
    
    const newPageItem = ref({
      id: null,
      highlight: false,
      title: '',
      type: '',
      custom_icon: '',
      link_title: '',
      link: '',
      new_window: false,
      page_id: pageid.value
    })

    function fetchData() {
      loading.value = true;
      // I prefer to use fetch
      // you can use use axios as an alternative
      return PageService.getPageItems(pageid.value).then(res => {
          // a non-200 response code
          if (res.status !== 200) {
            // create error instance with HTTP status text
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.data;
        })
        .then(json => {
          // set the response data
          pageItems.value = json;
        })
        .catch(err => {
          error.value = err;
          // In case a custom JSON error response was provided
          if (err.json) {
            return err.json.then(json => {
              // set the JSON response message
              error.value.message = json.message;
            });
          }
        })
        .then(() => {
          loading.value = false;
        });
    }
    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error,
      pageItems,
      fetchData,
      newPageItem
    };
  },
  methods: {
    async destroy() {
      if(confirm('are you sure?')) {
        PageService.delete(this.data.id)
        this.$router.push('/pages');
      }
    },
    refreshPageItems() {
      this.fetchData()
    }
  }
}
</script>