<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
            <input type="text" name="title" id="title" autocomplete="title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.title">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="last_name" class="block text-sm font-medium text-gray-700">Meta title</label>
            <input type="text" name="last_name" id="last_name" autocomplete="family-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_title">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="subtitle" class="block text-sm font-medium text-gray-700">Subtitle</label>
            <input type="text" name="subtitle" id="subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.subtitle">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="meta_description" class="block text-sm font-medium text-gray-700">Meta description</label>
            <input type="text" name="meta_description" id="meta_description" autocomplete="email" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.meta_description">
          </div>
          
          <div class="col-span-6 sm:col-span-6">
            <label for="slug" class="block text-sm font-medium text-gray-700">Item slug (voor url)</label>
            <input type="text" name="slug" id="slug" autocomplete="slug" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.slug">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="tags" class="block text-sm font-medium text-gray-700">Client logo's</label>
            <Multiselect
              v-model="selectedClientLogos"
              mode="tags"
              :options="allClientLogos"
              :searchable="true"
              :classes="{
                container: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded bg-white text-base leading-snug outline-none',
                containerDisabled: 'cursor-default bg-gray-100',
                containerOpen: 'rounded-b-none',
                containerOpenTop: 'rounded-t-none',
                containerActive: 'ring ring-blue-500 ring-opacity-30',
                singleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
                search: 'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
                tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
                tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
                tagDisabled: 'pr-2 !bg-gray-400 text-white',
                tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
                tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
                tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
                tagsSearch: 'absolute inset-0 border-0 outline-none appearance-none p-0 text-base font-sans box-border w-full',
                tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
                placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
                caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
                caretOpen: 'rotate-180 pointer-events-auto',
                clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
                clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
                spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
                dropdown: 'absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
                dropdownTop: '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
                dropdownHidden: 'hidden',
                options: 'flex flex-col p-0 m-0 list-none',
                optionsTop: 'flex-col-reverse',
                option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
                optionPointed: 'text-gray-800 bg-gray-100',
                optionSelected: 'text-white bg-blue-500',
                optionDisabled: 'text-gray-300 cursor-not-allowed',
                optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
                optionSelectedDisabled: 'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
                noOptions: 'py-2 px-3 text-gray-600 bg-white',
                noResults: 'py-2 px-3 text-gray-600 bg-white',
                fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
                spacer: 'h-9 py-px box-content',
              }"
            />
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="company_website" class="block text-sm font-medium text-gray-700">
              Published?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.published ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="togglePublished">
                <span class="sr-only">Published</span>
                <span aria-hidden="true" :class="c.published ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="company_website" class="block text-sm font-medium text-gray-700">
              Expertise pagina?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.is_proposition ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="toggleExpertise">
                <span class="sr-only">Expertise</span>
                <span aria-hidden="true" :class="c.is_proposition ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="company_website" class="block text-sm font-medium text-gray-700">
              Industry pagina?
            </label>
            <div class="mt-1 flex">
              <button type="button" :class="c.is_industry ? 'bg-orange-600': 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" role="switch" aria-checked="false" @click="toggleIndustry">
                <span class="sr-only">Published</span>
                <span aria-hidden="true" :class="c.is_industry ? 'translate-x-5': 'translate-x-0'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
            <TinyMceEditor v-model="c.description" model_name="Page" :model_id="c.id" :image="true" />
          </div>

          <div class="col-span-6 sm:col-span-6">
            <h2>Hoofdmenu opties</h2>
          </div>
          <div class="col-span-6 sm:col-span-2">
            <label for="tile_title" class="block text-sm font-medium text-gray-700"></label>
            <input type="text" name="tile_title" id="tile_title" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.tile_title">
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="tile_subtitle" class="block text-sm font-medium text-gray-700"></label>
            <input type="text" name="tile_subtitle" id="tile_subtitle" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.tile_subtitle">
          </div>

          <div class="col-span-6 sm:col-span-2">
            <label for="custom_icon" class="block text-sm font-medium text-gray-700"></label>
            <input type="text" name="custom_icon" id="custom_icon" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.custom_icon">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <select name="ParentId" v-model="selectedParentId">
              <option value=null>Geen / Hoofdpagina</option>
              <option v-for="pp in allParentPages" :key="pp.id" :value="pp.id" :selected="pp.id === c.ParentId">{{pp.title}}</option>
            </select>
          </div>
          
          <div class="col-span-6 sm:col-span-6">
            <router-link :to="'/pages/'+cp.id" v-for="cp in allChildrenPages" :key="cp.id" class="block">{{cp.title}}</router-link>
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="saving" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{saving ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import PageService from '../../../services/admin/pages/service';
import ClientLogoService from '../../../services/admin/client_logos/service';
import TinyMceEditor from '../generic/TinyMceEditor.vue';
import Multiselect from '@vueform/multiselect'

export default {
  name: 'PageFormComponent',
  props: {
    page: {
      type: Object,
      default: null
    }
  },
  components: {
    TinyMceEditor,
    Multiselect
  },
  setup(props) {
    const c = ref(null);
    const saving = ref(true);
    const selectedClientLogos = ref(true);
    const allClientLogos = ref(null);
    let allParentPages = ref(null);
    let allChildrenPages = ref(null);
    const selectedParentId = ref(null)

    let { page } = toRefs(props);

    saving.value = false;
    c.value = page.value;
    selectedParentId.value = page.value.ParentId;
    allClientLogos.value = [];
    selectedClientLogos.value = page.value.ClientLogos.map((t) => { return t.id });

    const fetchAllClientLogos = async function() {
      await ClientLogoService.list(1, 1000).then(({data}) =>{
        allClientLogos.value = data.items.map((t) => {
          return {
            value: t.id,
            label: t.title
          }
        })
      });
    }

    const fetchParentPages = async function() {
      const {data} = await PageService.getParentPages(page.value.id);
      allParentPages.value = data;
    }

    const fetchAllChildrenPages = async function() {
      const {data} = await PageService.getChildren(page.value.id);
      allChildrenPages.value = data;
    }

    onMounted(async () => {
      await fetchAllClientLogos();
      await fetchParentPages();
      await fetchAllChildrenPages();
    });

    return {
      c,
      allClientLogos,
      selectedClientLogos,
      allParentPages,
      allChildrenPages,
      selectedParentId,
      saving
    };
    
  },
  methods: {
    setClientLogos(selected) {
      this.selectedClientLogos = selected;
    },
    togglePublished() {
      this.c.published = !this.c.published;
    },
    toggleExpertise() {
      this.c.is_proposition = !this.c.is_proposition;
    },
    toggleIndustry() {
      this.c.is_industry = !this.c.is_industry;
    },
    async handleSave() {
      this.saving = true;
      await PageService.update({...this.c, ParentId: this.selectedParentId}, this.selectedClientLogos);
      this.saving = false;
    }
  }
}
</script>
